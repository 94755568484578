import React from 'react';
import ReactDOM from 'react-dom';
import Header from './components/Header';
import $ from 'jquery';
import PreorderNow from './components/PreorderNow';
import Slideshow from './components/Slideshow';

function idGo(id) {
    $('html, body').animate({
        scrollTop: ($("#"+id).offset().top)
    }, 500);
}

function resizeStuff() {
    $(".video-container").height($(".video-container").width()*.61);
}
setTimeout(resizeStuff, 100);
window.onresize = resizeStuff;

ReactDOM.render(
    <Header
        idGo={(id)=>idGo(id)}
    />,
    document.getElementById('header-container')
);

ReactDOM.render(
    <PreorderNow wait={90} />,
    document.getElementById('cuckoo-preorder')
);

ReactDOM.render(
    <Slideshow
        mapSlides={[]}
        id='photo-slideshow'
        container="photo-slideshow"
        slides={[
            <img className="slide-photo" src="/photos/Hickory Dickory-004.jpg" alt="Box" />,
            <img className="slide-photo" src="/photos/Hickory Dickory-009.jpg" alt="Blaze" />,
            <img className="slide-photo" src="/photos/Hickory Dickory-013.jpg" alt="" />,
            <img className="slide-photo" src="/photos/Hickory Dickory-015.jpg" alt="" />,
            <img className="slide-photo" src="/photos/Hickory Dickory-017.jpg" alt="" />,
            <img className="slide-photo" src="/photos/Hickory Dickory-018.jpg" alt="" />,
            <img className="slide-photo" src="/photos/Hickory Dickory-019.jpg" alt="" />,
            <img className="slide-photo" src="/photos/Hickory Dickory-022.jpg" alt="" />,
            <img className="slide-photo" src="/photos/Hickory Dickory-027.jpg" alt="" />,
            <img className="slide-photo" src="/photos/Hickory Dickory-040.jpg" alt="" />,
            <img className="slide-photo" src="/photos/Hickory Dickory-056.jpg" alt="" />,
            <img className="slide-photo" src="/photos/Hickory Dickory-062.jpg" alt="" />,
        ]}
    />
    , document.getElementById('photo-slideshow')
);