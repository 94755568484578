import React from 'react';
import Headroom from 'headroom.js';

function getPreorderLink() {
    const urlParams = new URLSearchParams(window.location.search);
    let source = urlParams.get('source');
    if(source) return "https://www.plaidhatgames.com/hd-preorder-"+source.toLowerCase()+"/";
    return "https://www.plaidhatgames.com/hd-preorder-pre/";
}

export default class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showAbout: false,
        };
    }

    componentDidMount() {
        setTimeout(()=>{
            let headroom  = new Headroom(
                document.querySelector("#header-container"),
                {
                    offset: 75,
                    tolerance: {
                        up: 4,
                        down: 0
                    },
                    onUnpin: ()=>this.setState({showAbout: false}),
                }
            );
            headroom.init();
        }, 1);
    }

    idGo(id) {
        this.setState({showAbout: false});
        this.props.idGo(id);
    }

    render() {
        return(<>
            <header>
                <a className="company-logo-container" href="https://www.plaidhatgames.com" target="_blank" rel="nofollow noreferrer">
                    <img
                        className="company-logo" src='/hdimages/phgyellow.png' alt="Plaid Hat Games"
                    />
                </a>
                <nav>
                    <ul>
                        <li onClick={()=>this.idGo("gameplay")}>Gameplay</li>
                        <li onClick={()=>this.idGo("setup")}>Components</li>
                        <li className="li-preorder" onClick={()=>this.idGo("preorder")}>Order</li>
                        <li onClick={()=>this.idGo("reviews")}>Reviews</li>
                        <li onClick={()=>this.idGo("video-area")}>Video</li>
                    </ul>
                </nav>
                <a href={getPreorderLink()} className="header-preorder">Order Now!</a>
            </header>
        </>)
    }
}