import React from 'react';
import $ from 'jquery';
import { motion, AnimatePresence} from "framer-motion";
import Xwiper from 'xwiper';

export default class Slideshow extends React.Component {
    constructor(props) {
        super(props);
        this.timeout = null;
        this.state = {
            activeSlide: 0,
            prevSlide: null,
        }
        this.gesture = null;
    }

    componentDidMount() {
        this.goSlide(this.props.startingSlide || 0);
        this.gesture = new Xwiper('#'+this.props.id);
        this.gesture.onSwipeLeft(()=>{
            this.adjustSlide(1);
        });
        this.gesture.onSwipeRight(()=>{
            this.adjustSlide(-1);
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.state.activeSlide !== prevState.activeSlide) {
            if(this.props.mapSlides.includes(this.state.activeSlide)) {
                $("#"+this.props.container).addClass("with-map");
            } else {
                $("#"+this.props.container).removeClass("with-map");
            }
        }
    }

    adjustSlide(num) {
        let new_i = this.state.activeSlide+num;
        if(new_i<0) new_i = this.props.slides.length-1;
        if(new_i>this.props.slides.length-1) new_i = 0;
        this.goSlide(new_i);
    }

    goSlide(i) {
        this.setState({activeSlide: i, prevSlide: this.state.activeSlide,});
        clearTimeout(this.timeout);
        this.timeout = setTimeout(()=>this.adjustSlide(1), this.props.pause || 10000);
    }

    render() {
        let leftOut = false;
        if(this.state.prevSlide !== null) {
            if(this.state.prevSlide < this.state.activeSlide) {
                leftOut = true;
            }
        }
        return(
            <>
                <AnimatePresence
                    custom={leftOut}
                    initial={false}
                >
                    <motion.div
                        className="slide-container"
                        transition={{duration: .5}}
                        key={"slide-"+this.state.activeSlide}
                        custom={leftOut}
                        variants={{
                            slideIn: (l)=>{
                                if(l) return {x: "100%",};
                                return {x: "-100%",};
                            },
                            slideOut: (l)=>{
                                if(l) return {x: "-100%"};
                                return {x: "100%"};
                            }
                        }}
                        initial="slideIn"
                        animate={{x: "0%",}}
                        exit="slideOut"
                    >
                        {this.props.slides[this.state.activeSlide]}
                    </motion.div>
                </AnimatePresence>
                <div className="slideshow-dots">
                    {this.props.slides.map((s, i)=>(
                        <div
                            key={i}
                            onClick={()=>this.goSlide(i)}
                            className={"slideshow-dot"+(this.state.activeSlide===i ? " active" : "")}
                        ></div>
                    ))}
                </div>
            </>
        );
    }
}