import React from 'react';
import {motion} from 'framer-motion';
import {apiPromise} from "./NewsletterForm";

function getPreorderLink() {
    const urlParams = new URLSearchParams(window.location.search);
    let source = urlParams.get('source');
    if(source) return "https://www.plaidhatgames.com/hd-preorder-"+source.toLowerCase()+"/";
    return "https://www.plaidhatgames.com/hd-preorder-pre/";
}

const STATUSES = {
    HIDDEN: 'hidden',
    PREORDER: 'preorder',
    NEWSLETTER: 'newsletter',
}

const FORM_STATES = {
    FILLING_OUT: 'filling out',
    SUBMITTING: 'submitting',
    SUBMITTED: 'submitted',
}

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export default class PreorderNow extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            status: STATUSES.HIDDEN,
            email: "",
            form_status: FORM_STATES.FILLING_OUT,
        }
    }

    submitForm() {
        if(!validateEmail(this.state.email)) {
            return;
        } else {
            this.setState({form_status: FORM_STATES.SUBMITTING});
            apiPromise(
                "https://www.plaidhatgames.com/newsletter-signup/hickory-dickory/",
                "POST",
                {"email": this.state.email},
            ).then(r=>{
                this.setState({form_status: FORM_STATES.SUBMITTED});
                setTimeout(()=>this.setState({status: STATUSES.HIDDEN}), 1000);
            });
        }
    }

    componentDidMount() {
        setTimeout(()=>this.setState({status: STATUSES.PREORDER}), this.props.wait*1000);
    }

    render() {
        switch(this.state.status) {
            case STATUSES.PREORDER:
            case STATUSES.NEWSLETTER:
                return(<motion.div className="cuckoo-preorder" initial={{y: 140,}} animate={{y: 1,}} transition={{duration: .3, type: "ease-out"}}>
                    {this.state.status===STATUSES.PREORDER
                        ? <>
                            <a className="preorder-button" href={getPreorderLink()}>Preorder Now!</a>
                            <div className="naw" onClick={()=>this.setState({status: STATUSES.NEWSLETTER})}>Maybe Later</div>
                        </>
                        : <>
                            <div className="get-updates">Get updates on this game!</div>
                            <input type="text" value={this.state.email} onChange={(e)=>this.setState({email: e.target.value})} placeholder="Enter your email" />
                            <div className="submit-area">
                                {this.state.form_status === FORM_STATES.FILLING_OUT ?
                                    <>
                                        <button
                                            className="email-submit"
                                            onClick={() => this.submitForm()}
                                            disabled={this.state.email === ""}
                                        >Submit
                                        </button>
                                        <div className="naw" onClick={()=>this.setState({status: STATUSES.HIDDEN})}>No Thanks</div>
                                    </>
                                : this.state.form_status === FORM_STATES.SUBMITTING ?
                                    <div className="submitting">Submitting...</div>
                                :
                                    <div className="submitting">Success!</div>
                                }
                            </div>
                        </>
                    }
                    {this.state.status===STATUSES.PREORDER ? <motion.img
                        src="/hdimages/cuckoo.png" alt="Lord Cuckoo" className="cuckoo"
                        initial={{y: 140,}} animate={{y: 1}} transition={{duration: .3, type: "ease-out", delay: .5}}
                    /> : null }
                    <motion.img
                        src="/hdimages/speech.png" alt="Cuckoo! It's time!" className="speech"
                        initial={{y: 140, opacity: 1}} animate={{y: [140, 1, 1, 1], opacity: [1, 1, 1, 0]}} transition={{type: "ease-out", delay: .5, times: [0, .1, .9, 1], duration: 3,}}
                    />
                </motion.div>)
            default:
                return null;
        }
    }
}