import React from 'react';

const FORM_STATUS = {
    FILLING_OUT: 'filling out',
    SUBMITTING: 'submitting',
    SUBMITTED: 'submitted',
}

export default class NewsletterForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            form_status: FORM_STATUS.FILLING_OUT,
            email: "",
            form_error: null,
        };
    }

    submitForm() {
        if(!validateEmail(this.state.email)) {
            this.setState({form_error: "You must enter a valid email address."});
        } else {
            this.setState({form_status: FORM_STATUS.SUBMITTING});
            apiPromise(
                "https://www.plaidhatgames.com/newsletter-signup/hickory-dickory/",
                "POST",
                {"email": this.state.email},
            ).then(r=>{
                if(r.ok===true) {
                    this.setState({form_status: FORM_STATUS.SUBMITTED});
                } else {
                    this.setState({
                        form_status: FORM_STATUS.FILLING_OUT,
                        form_error: 'There was an error submitting your email: '+r.message,
                    });
                }
            });
        }
    }

    inputChange(e) {
        this.setState({email: e.target.value, form_error: null});
    }

    render() {
        if(this.state.form_status===FORM_STATUS.FILLING_OUT) {
            return (
                <div className="form">
                    <input
                        type="text"
                        className="email-input"
                        value={this.state.email}
                        onChange={(e) => this.inputChange(e)}
                        maxLength={100}
                        placeholder="Enter Your Email"
                    />
                    {this.state.form_error
                        ? <div className="error">{this.state.form_error}</div>
                        : null
                    }
                    <button
                        type="submit"
                        className="sign-up"
                        disabled={this.state.email === ""}
                        onClick={() => this.submitForm()}
                    >Submit
                    </button>
                </div>
            );
        }
        if(this.state.form_status===FORM_STATUS.SUBMITTING) {
            return (<div className="status">Submitting your email...</div>);
        }
        return(<div className="success" id="success">Your email was submitted successfully.</div>);
    }
}

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function apiPromise(whereto, method='GET', body=null) {
    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };
    let req_opt = {
        method: method,
        headers: headers,
    };
    if(body) {
        req_opt['body'] = JSON.stringify(body);
    }
    return new Promise(resolve=>{
        fetch(
            whereto,
            req_opt,
        ).then(r=>{
            if(r.status===200) {
                r.json().then((d)=>{
                    resolve(d);
                });
            } else {
                resolve({
                    ok: false,
                    message: "Error: "+r.status+" "+r.statusText,
                });
            }
        })
    });
}